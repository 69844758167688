<script setup lang="ts">
import PrimaryButton from "@/components/utils/PrimaryButton/PrimaryButton.vue";
import Callout from "@/components/utils/Callout.vue";
import CustomerCareCard, { type Cta } from "./CustomerCareCard.vue";
import { SOCIALS } from "@consts";
import useAosRefresh from "@/composables/useAosRefresh";

import {useI18n} from "vue-i18n";
const {t} = useI18n({
  useScope: 'global'
});
interface CustomerCare {
  icon: string;
  title: string;
  description: string;
  cta: Cta
}

useAosRefresh()

const customerCares: CustomerCare[] = [
  {
    icon: "i-mdi:chat-outline",
    title: "Official support",
    description: "Ask us a question at any time, even before you register - we’re always happy to help.",
    cta: {
      label: "Go to support page",
      href: "/support",
    },
  },
  {
    icon: "i-gg:profile",
    title: "Assigned success agent",
    description: "We assign an agent for every single customer, your assigned agent monitors your performance, and recommends actions when necessary.",
    cta: {
      label: "Learn more",
      href: "/support",
    },
  },
  {
    icon: "i-ri:group-line",
    title: "Open Community",
    description: "Be the first to know about upcoming DipSway features, and meet the community.",
    cta: {
      label: "Join the telegram community",
      href: SOCIALS.telegram.href,
    },
  },
]

</script>

<template>
  <section class="container isolate">
    <div class="flex flex-col items-center w-full justify-center">
      <Callout
        data-aos
        class="duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5)" 
        icon="i-mdi:heart-outline"
      >
        {{t('here_when_you_need_help')}}
      </Callout>
      <h2 
        data-aos
        :class="[
          'mt-4 heading-2 text-center',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-200'
        ]"
      >
        {{ t('dipsway_customer_care') }}
      </h2>
      <p 
        data-aos
        :class="[
          'text-brand-secondary leading-[150%] mt-6.5 text-center max-w-[41rem]',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-400'
        ]"
      >
        {{t('for_us_top_10_percent') }}
      </p>
      <div 
        data-aos
        :class="[
          'relative flex flex-col items-center mt-12',
          'duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-600'
        ]"
      >
        <!-- lighting effect start -->
        <div :class="[
          'bg-effect-lighting bg-brand-gradient -z-5',
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          'aspect-video w-[200%]',
        ]" />
        <!-- lighting effect end -->
        <PrimaryButton :href="SOCIALS.telegram.href">
          {{ t('join_the_telegram_community') }}
        </PrimaryButton>
      </div>
    </div>
    <div :class="[
      'w-full grid grid-cols-1 place-items-center gap-y-7 gap-x-5 mt-13',
      'lg:(items-baseline grid-cols-3 gap-y-0)',
    ]">
      <template v-for="(customerCare, index) in customerCares">
        <CustomerCareCard 
          data-aos
          :class="[
            index === 1 ? 'order-last lg:order-none' : `order-none`,
            `duration-700 ease-in-out [&:not(.aos-animate)]:(opacity-0 translate-y-5) delay-${(index + 1) * 200}`
          ]" 
          v-bind="customerCare"
        />
      </template>
    </div>
  </section>
</template>
