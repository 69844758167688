<script setup lang="ts">
import SecondaryButton from '@/components/utils/SecondaryButton.vue';

export interface Cta { 
  label: string
  href: string 
}

interface Props {
  icon: string
  title: string
  description: string
  cta: Cta
}

const props = defineProps<Props>()
</script>

<template>
  <div class="border-2 border-brand-stroke/10 rounded-3 p-7.5 flex flex-col items-baseline w-full sm:w-3/4 lg:w-full relative group/button" >
    <span :class="[props.icon, 'inline-block text-6 bg-brand-gradient']" />
    <span class="mt-2.5 text-white text-5.5 font-bold lg:max-w-[13rem]">{{
      props.title
    }}</span>
    <p class="text-brand-secondary mt-3 leading-[150%] lg:max-w-[15.5rem]">
      {{ props.description }}
    </p>
    <a :href="props.cta.href" >
      <span class="absolute inset-0" ></span>
      <SecondaryButton 
        class="mt-9"
        as="span"
      >
        {{ props.cta.label }}
      </SecondaryButton>      
    </a>
  </div>
</template>
